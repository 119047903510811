<template>
  <div
    class="welcome"
    :style="{
      backgroundImage: 'url(' + require('@../../../assets/home.jpg') + ')',
    }"
  >
    <header-main 
      :forceHide="['login', 'register']"
    >
    </header-main>
    <footer-main></footer-main>
  </div>

  <modal-dialog
    v-if="showError"
    :actions="errorActions"
    title="Stay Calm!"
    @close="showError = false"
  >
    <p>{{ errorMessage }}</p>
  </modal-dialog>

</template>

<script>
import { defineComponent } from "vue";
import HeaderMain from "../controls/Header";
import ModalDialog from "../controls/ModalDialog.vue";
import ImagePreview from "../pages/modal/ImagePreview.vue";
import { ErrorLookup } from "../../api/classes/ErrorLookup";
import FooterMain from "../controls/Footer.vue";

export default defineComponent({
  name: "Welcome",
  title: "Welcome",
  components: {
    HeaderMain,
    ModalDialog,
    FooterMain,
  },

  data() {
    return {
      showError: false,
      errorMessage: "",
      showImagePreview: false,
    };
  },

  computed: {
    errorActions() {
      let vm = this;
      let actions = [
        {
          id: 1,
          title: "Close",
          isSecondary: true,
          handle: () => {
            vm.showError = false;
          },
        },
      ];
      if (this.$route.query && this.$route.query.returnUrl) {
        actions.push({
          id: 1,
          title: "Go Back",
          handle: () => {
            vm.$router.push(vm.$route.query.returnUrl);
          },
        });
      }
      return actions;
    },
  },

  mounted() {
    let error = this.$route.query.error;
    if (error) {
      this.showError = true;
      this.errorMessage = ErrorLookup.messageForError(error);
    }
  },
  // computed: {
  //     user() {
  //         return this.$store.state.user;
  //     },
  //     isLoggedIn() {
  //         return this.user && this.user.loggedIn;
  //     },
  //     dashboardUrl() {
  //         if (this.user.loggedIn) {
  //             if (this.user.username) {
  //                 return `/${this.user.username}`;
  //             } else {
  //                 return `/signup2`;
  //             }
  //         }
  //         //shouldn't get here
  //         return "/";
  //     },
  // },
});
</script>

<style scoped>
.welcome {
  height: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: top;
  background-position: 100% 20%;
}

/* .header {
  position:absolute;
  display:flex;
  justify-content: space-between;
  padding: 20px 10px 0px 10px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.buttonGroup {
  float: right;
}

.heroText {
  width:100%;
  margin: 70% 0% 10% 0%;
  padding: 10px 5% 10px 50%;
}

.heroText img {
  width:700px;
} */

@media screen and (max-width: 650px) {
  .welcome {
    background-position: 50%;
  }
}
</style>
